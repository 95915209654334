import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://3e2cad413d7506a4cb8380bf5219b529@o4507715543302144.ingest.us.sentry.io/4507715557654528",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 1,
});
import { init } from 'astro/virtual-modules/prefetch.js';init()